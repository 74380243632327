import $ from 'jquery';
import Overlay from './overlay';
import template from '../templates/picker.html';
import Rails from 'rails-ujs';

const URL = '/nb/admin/bilder.json';

export default class Picker {
  constructor(callback) {
    this.callback = callback;
    this.url = URL;
    this.query = null;
    this.images = {};
    this.page = 0;
  }

  render() {
    this.el = $(template);
    this.overlay = new Overlay();
    this.overlay.render(this.el);
    this.bind();
    this.load();
    return this;
  }

  bind() {
    const el = this.el;

    el.on('click', '.image_picker_load', e => {
      this.load()
      e.preventDefault();
    });

    el.on('click', '.image_picker_image', e => {
      const id = $(e.target).data('id');
      this.select(id);
      e.preventDefault();
    });

    el.on('submit', '.image_picker_search', e => {
      const query = el.find('input[name=tags]').val();
      this.search(query);
      e.preventDefault();
    });

    el.on('submit', '.image_picker_upload', e => {
      this.upload();
      e.preventDefault();
    });

    el.on('click', '.image_picker_tab', e => {
      const target = $(e.target).attr('href');
      this.tab(target);
      e.preventDefault();
    });

    el.on('click', '.image_picker_drop', e => {
      this.pick();
      e.preventDefault();
    });

    el.on('drop', '.image_picker_drop', e => {
      const file = e.originalEvent.dataTransfer.files[0];
      e.preventDefault();
      if (!file) return;
      this.file = file;
      this.preview(file);
    });

    el.on('dragented, dragover', e => {
      e.preventDefault();
      e.stopPropagation();
    })
  }

  load(query) {
    const button = this.el.find('.image_picker_load');

    $.getJSON(this.url, {
      page: ++this.page,
      tags: this.query
    }, data => {
      if (data.length) button.show();
      else button.hide();
      data.forEach(image => {
        this.images[image.id] = image;
        this.add(image);
      });
    });

    return this;
  }

  search(query) {
    this.el.find('.image_picker_images').empty();
    this.query = query;
    this.page = 0;
    this.load();
    return this;
  }

  pick() {
    const input = this.el.find('.image_picker_input');

    input.one('change', e => {
      const file = e.target.files[0];
      if (!file) return;
      this.file = file;
      this.preview(file);
    });

    input.click();
    return this;
  }

  preview(file) {
    const zone = this.el.find('.image_picker_drop');
    const reader = new FileReader();

    reader.onload = e => {
      zone.css('background-image', `url("${reader.result}")`);
      zone.empty();
    }

    reader.readAsDataURL(file);
    return this;
  }

  upload() {
    const form = this.el.find('.image_picker_upload');
    const button = form.find('button');
    const data = new FormData(form[0]);

    data.append('image[file]', this.file);
    form.find('.errors').empty();
    button.attr('disabled', true);
    button.addClass('loading');

    $.ajax({
      method: 'post',
      url: this.url,
      data: data,
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      },
      processData: false,
      contentType: false
    }).done(image => {
      this.callback(image);
      this.destroy();
    }).fail(xhr => {
      const errors = xhr.responseJSON;
      if (!Array.isArray(errors)) {
        alert('Noe gikk galt, vennligst prøv igjen');
        return;
      }
      errors.forEach(err => this.onerror(err));
    }).always(() => {
      button.attr('disabled', false);
      button.removeClass('loading');
    });
  }

  tab(tab) {
    const tabs = this.el.find('.image_picker_tab');
    tabs.each((i, elem) => {
      const el = $(elem);
      const id = el.attr('href');
      if (id == tab) {
        el.addClass('active');
        $(id).show();
      } else {
        el.removeClass('active');
        $(id).hide();
      }
    })
  }

  select(id) {
    this.callback(this.images[id]);
    this.destroy();
  }

  add(image) {
    const list = this.el.find('.image_picker_images');
    const el = $('<img>').addClass('image_picker_image');
    el.attr('src', image.file.medium.url);
    el.data('id', image.id);
    list.append(el);
    return this;
  }

  onerror(err) {
    const li = $('<li>').addClass('error');
    this.el.find('.errors').append(li);
    li.text(err);
  }

  destroy() {
    this.overlay.destroy();
  }
}
