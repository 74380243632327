import $ from 'jquery';
import template from '../templates/overlay.html';

export default class Overlay {
  render(inner) {
    const body = $('body');
    this.el = $(template);
    this.el.append(inner);
    body.addClass('overlay_visible');
    body.append(this.el);
    this.bind();
    return this;
  }

  bind() {
    this.el.on('click', e => this.onclick(e))
  }

  onclick(e) {
    const target = $(e.target);
    if (target.is(this.el)) this.destroy();
  }

  destroy() {
    $('body').removeClass('overlay_visible');
    this.el.remove();
  }
}
