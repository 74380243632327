import $ from 'jquery';
import TextareaEditor from 'textarea-editor';
import Picker from './picker';
import template from '../templates/editor.html';

export default class Editor {
  constructor(el) {
    this.editor = new TextareaEditor(el);
  }

  render() {
    const textarea = this.editor.el;
    this.el = $(template);
    this.el.insertBefore(textarea);
    this.el.append(textarea);
    this.bind();
    return this;
  }

  bind() {
    const toolbar = this.el.find('.toolbar');
    const textarea = $(this.editor.el);
    toolbar.on('mousedown', false);
    toolbar.on('click', e => this.onclick(e));
    textarea.on('keydown', e => this.onkeydown(e));
  }

  onkeydown(e) {
    const key = e.which;
    const cmd = e.metaKey || e.ctrlKey;

    if (!cmd) return;

    switch (key) {
      case 66:
        this.editor.toggle('bold');
        break;
      case 73:
        this.editor.toggle('italic');
        break;
      default: return;
    }

    e.preventDefault();
  }

  onclick(e) {
    const editor = this.editor;
    const target = $(e.target);
    const command = target.data('command');

    if (!command) return;
    e.preventDefault();

    if (command == 'image') {
      if (editor.hasFormat('image')) return editor.unformat('image');
      const range = editor.range();
      const callback = (image) => {
        editor.range(range);
        editor.format('image', image.file.url);
      };
      const picker = new Picker(callback);
      picker.render();
      return;
    }

    if (command == 'link') {
      if (editor.hasFormat('link')) return editor.unformat('link');
      const url = prompt('URL:');
      if (url) {
        editor.format('link', url);
      }
      return;
    }

    editor.toggle(command);
  }
}
