import $ from 'jquery';
import Cookie from 'js-cookie';
import Editor from './editor';

import Turbolinks from 'turbolinks'
import Rails from 'rails-ujs';

Rails.start();
Turbolinks.start();

$(document).on('turbolinks:load', fit);

$(document).on('turbolinks:load', () => {
  $('.navbar').on('click', '.navbar_toggle', function (e) {
    $('.navbar_links').toggleClass('show');
    $(this).toggleClass('toggled');
    return false;
  });

  $('.cookie_notice_accept').on('click', function () {
    $('.cookie_notice').remove();
    Cookie.set('cookie_notice_accepted', true, { expires: 365 });
    return false;
  });

  $('textarea[data-markdown]').each((i, el) => {
    let editor = new Editor(el);
    editor.render();
  });

  $('a[href*="/filer/"]').each((i, el) => {
    $(el).attr('data-turbolinks', 'false');
  });
});

function fit () {
  //$('.autoscale').textfill('h1, h2, h3, h4');
}

$.fn.textfill = function (selector) {
  return this.find(selector).each(function(){
    const el = $(this);
    el.css('font-size', '');
    el.css('white-space', 'nowrap');

    const parent = el.parent();
    const ratio = parent.width() / el.width();
    let size = parseInt(el.css('font-size'), 10);

    // Initial guess
    if (ratio < 1) {
      size = Math.floor(size * ratio);
      el.css('font-size', `${size}px`);
    }

    // Fallback
    while (el.width() > el.parent().width()) {
      el.css('font-size', --size + 'px');
      if (size <= 10) break;
    }
  });
};
